import React from 'react';
import { Icon } from 'antd';


function Footer() {
    return (
        <div style={{
            height: '80px', display: 'flex',
            flexDirection: 'column', alignItems: 'center',
            justifyContent: 'center', fontSize:'1rem'
        }}>
           <h3>Coming soon... <Icon type="hourglass" spin theme="twoTone" ></Icon></h3>
        </div>
    )
}

export default Footer
