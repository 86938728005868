import React from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import frontShot from "./assets/front-shot.jpg";
import sideAngleShot from "./assets/side-angle-shot.jpg";
import topDownShot from "./assets/top-down-shot.jpg";
import closeUpShot from "./assets/close-up-shot.jpg";
import contextShot from "./assets/context-shot.jpg";

const ProductImgTutorial = () => {
  const { t } = useTranslation();
  return (
    <div className="tutorial-container">
      <h2>{t("howToTakePics.mainTitle")}</h2>
      <p>{t("howToTakePics.overView")}</p>
      <ol>
        <li>
          <strong> {t("howToTakePics.frontShotTitle")}</strong>{" "}
          {t("howToTakePics.frontShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.frontShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.frontShotTip")}
        </li>

        <li>
          <strong> {t("howToTakePics.sideAngleShotTitle")}</strong>{" "}
          {t("howToTakePics.sideAngleShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.sideAngleShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.sideAngleShotTip")}
        </li>

        <li>
          <strong> {t("howToTakePics.topDownShotTitle")}</strong>{" "}
          {t("howToTakePics.topDownShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.topDownShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.topDownShotTip")}
        </li>

        <li>
          <strong> {t("howToTakePics.closeUpShotTitle")}</strong>{" "}
          {t("howToTakePics.closeUpShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.closeUpShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.closeUpShotTip")}
        </li>

        <li>
          <strong> {t("howToTakePics.contextShotTitle")}</strong>{" "}
          {t("howToTakePics.contextShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.contextShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.contextShotTip")}
        </li>
      </ol>

      <h3>{t("howToTakePics.additionalTipsTitle")}</h3>
      <ul>
        <li>
          <strong>{t("howToTakePics.additionalTips.lightingTitle")}</strong>{" "}
          {t("howToTakePics.additionalTips.lightingExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.additionalTips.lightingFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.additionalTips.lightingTip")}
        </li>
        <li>
          <strong>{t("howToTakePics.additionalTips.backgroundTitle")}</strong>{" "}
          {t("howToTakePics.additionalTips.backgroundExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.additionalTips.backgroundFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.additionalTips.backgroundTip")}
        </li>
        <li>
          <strong>{t("howToTakePics.additionalTips.anglesTitle")}</strong>{" "}
          {t("howToTakePics.additionalTips.anglesExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.additionalTips.anglesFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.additionalTips.anglesTip")}
        </li>
        <li>
          <strong>{t("howToTakePics.additionalTips.editingTitle")}</strong>{" "}
          {t("howToTakePics.additionalTips.editingExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.additionalTips.editingFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.additionalTips.editingTip")}
        </li>
      </ul>
      <p>{t("howToTakePics.outro")}</p>
      <div className="example-images">
        <Divider>
          <h1>{t("howToTakePics.example")}</h1>
        </Divider>
        <div className="example-image">
          <img src={frontShot} alt={t("howToTakePics.frontShotTitle")} />
          <p>{t("howToTakePics.frontShotTitle")}</p>
        </div>
        <div className="example-image">
          <img
            src={sideAngleShot}
            alt={t("howToTakePics.sideAngleShotTitle")}
          />
          <p>{t("howToTakePics.sideAngleShotTitle")}</p>
        </div>
        <div className="example-image">
          <img src={topDownShot} alt={t("howToTakePics.topDownShotTitle")} />
          <p>{t("howToTakePics.topDownShotTitle")}</p>
        </div>
        <div className="example-image">
          <img src={closeUpShot} alt={t("howToTakePics.closeUpShotTitle")} />
          <p>{t("howToTakePics.closeUpShotTitle")}</p>
        </div>
        <div className="example-image">
          <img src={contextShot} alt={t("howToTakePics.contextShotTitle")} />
          <p>{t("howToTakePics.contextShotTitle")}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductImgTutorial;
